import { ApolloProvider } from '@apollo/client'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import client from './apollo'
import App from './App'
import { AuthenticationContextProvider } from './contexts/AuthenticationContext'
import reportWebVitals from './reportWebVitals'
import './translations'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterMoment from '@mui/lab/AdapterMoment'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme'
import { TitleContextProvider } from './contexts/TitleContext'
import CssBaseline from '@mui/material/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { GoogleApiProvider } from './contexts/GoogleApiContext'
import { HotjarProvider } from './components/Hotjar'
import { FaviconFetcher } from './components/FaviconFetcher'
import { SettingsContextProvider } from './contexts/SettingsContext'
import { MetadataContextProvider } from './contexts/MetadataProvider'
import { ApplicationLoader } from './components/ApplicationLoader'

ReactDOM.render(
  <StrictMode>
    <GoogleApiProvider>
      <HotjarProvider />
      <MetadataContextProvider>
        <FaviconFetcher />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
              <CssBaseline />
              <Router>
                <TitleContextProvider>
                  <ApolloProvider client={client}>
                    <AuthenticationContextProvider>
                      <SettingsContextProvider>
                        <ApplicationLoader>
                          <App />
                        </ApplicationLoader>
                      </SettingsContextProvider>
                    </AuthenticationContextProvider>
                  </ApolloProvider>
                </TitleContextProvider>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </MetadataContextProvider>
    </GoogleApiProvider>
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
