import { createContext, useContext } from 'react'
import {
  emptyMetadata,
  IMetadata,
  useMetadataContext,
} from '../hooks/useMetadata'

const MetadataContext = createContext<IMetadata>({
  metadata: emptyMetadata,
  refetchMetadata: () => {},
  loading: true,
  error: null,
})

export const useMetadata = () => {
  return useContext(MetadataContext)
}

interface IProps {
  children: React.ReactNode
}

export const MetadataContextProvider = ({ children }: IProps) => {
  const { metadata, refetchMetadata, loading, error } = useMetadataContext()

  return (
    <MetadataContext.Provider
      value={{ metadata, refetchMetadata, loading, error }}
    >
      {children}
    </MetadataContext.Provider>
  )
}
