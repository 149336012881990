import { createTheme } from '@mui/material'
import { keyframes } from '@mui/styled-engine'

let defaultTheme = createTheme({})

const palette = {
  primary: {
    main: '#001A8A',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#3FC5D6',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#E03E37',
  },
  warning: {
    main: '#FF914B',
  },
  success: {
    main: '#40DE79',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#3A96ED',
  },
}

// const palette = {
// primary: {
//   main: '#001A8A',
//   contrastText: '#FFFFFF',
// },
// secondary: {
//   main: '#3FC5D6',
//   contrastText: '#FFFFFF',
// },
// error: {
//   main: '#E03E37',
// },
// warning: {
//   main: '#FF914B',
// },
// success: {
//   main: '#66E593',
//   contrastText: '#FFFFFF',
// },
// info: {
//   main: '#3A96ED',
// },
// }

export const theme = createTheme({
  typography: {
    fontFamily: [
      'novel-sans-pro',
      'Ubuntu',
      'Roboto',
      '-apple-system',
      'sans-serif',
    ].join(','),
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     contained: {
    //       backgroundColor: palette.secondary.light,
    //     },
    //   },
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.background.default,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translate(0, 100%)',
          width: '100%',
          fontSize: '0.65rem',
          animation: `${keyframes`
          0 %  {
            transform: translate(0, 100%);
          },
          100% {
            transform: translate(-52%, 100%);
          }
          `} .2s`,
          animationFillMode: 'forwards',
        },
      },
    },
  },
  palette,
})
