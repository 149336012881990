import { Box } from '@mui/system'
import NormalLogo from './../assets/images/logo-normal.svg'
import LogoLetter from './../assets/images/logo-letter.svg'
import LogoInverse from './../assets/images/logo-normal-inverse.svg'

interface IProps {
  width?: string
  maxWidth?: string
  height?: string | number
  maxHeight?: string | number
  type: 'logo-normal' | 'logo-normal-inverse' | 'logo-letter'
}

export const Logo = ({
  type,
  width = 'auto',
  height = '100%',
  maxHeight = '64px',
  maxWidth = '500px',
}: IProps) => {
  const getLogo = () => {
    switch (type) {
      case 'logo-normal':
        return NormalLogo
      case 'logo-normal-inverse':
        return LogoInverse
      case 'logo-letter':
        return LogoLetter
      default:
        return NormalLogo
    }
  }

  return (
    <Box
      component="img"
      sx={{
        width,
        height,
        maxHeight,
        maxWidth,
      }}
      alt="Skila logo"
      src={getLogo()}
    />
  )
}
