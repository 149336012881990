import { LoadingButton } from '@mui/lab'
import { Backdrop, Box, Checkbox, Link, Slide, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useState } from 'react'
import { ACCEPT_TERMS_MUTATION } from '../apollo/mutations'
import { useAuth } from '../contexts/AuthenticationContext'
import { useMutation } from '../hooks/useMutation'

const TermsOfUseContainerInner = styled<any>('div', {
  shouldForwardProp: (prop) => !['empty'].includes(prop as string),
})(({ theme, empty }: any) => {
  return {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: 350,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }
})

export const TermsOfUse = () => {
  const { refetch, user } = useAuth()
  const [updateUser, { loading }] = useMutation(ACCEPT_TERMS_MUTATION)
  const [agree, setAgree] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleAgree = () => {
    setError(null)
    setAgree((prevState) => !prevState)
  }

  const clickAgree = async () => {
    if (agree) {
      try {
        await updateUser({
          variables: {
            userId: user?.userId,
            termsAccepted: 1,
          },
        })
        refetch()
      } catch (e: any) {
        setError(e.message)
      }
      return
    }
    setError('Please agree with the above to proceed.')
  }

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <Slide in={true} mountOnEnter unmountOnExit>
        <TermsOfUseContainerInner>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 1,
              }}
            >
              <Checkbox
                checked={agree}
                onChange={handleAgree}
                name="agreeToTermsOfUse"
              />
              <Typography>
                I have read and agree to{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://storage.googleapis.com/skila/Skila%20Terms%20and%20conditions.pdf"
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://storage.googleapis.com/skila/Skila%20Privacy%20Policy.pdf"
                >
                  Privacy policy
                </Link>
                .
              </Typography>
            </Box>
            {error && (
              <Box sx={{ marginBottom: 1, color: 'red' }}>
                <Typography variant="caption">{error}</Typography>
              </Box>
            )}
            <LoadingButton
              loading={loading}
              onClick={clickAgree}
              variant="contained"
            >
              Confirm
            </LoadingButton>
          </Box>
        </TermsOfUseContainerInner>
      </Slide>
    </Backdrop>
  )
}
