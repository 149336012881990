import { Redirect, Route } from 'react-router'
import { useAuth } from '../contexts/AuthenticationContext'
import { Role } from '../types/user'
import { NotAuthorized } from './NotAuthorized'
import { TermsOfUse } from './TermsOfUse'

/*
    Components wrapped by this will redirect requests to the
    login page in case the user isn't authenticated.
*/
export const PrivateRoute = ({
  component,
  roles,
  ...rest
}: {
  component: React.ReactNode
  roles: Role[]
  [x: string]: any
}) => {
  const { user } = useAuth()

  if (user && !user.termsAccepted) {
    return <TermsOfUse />
  }

  if (user && !roles.includes(user.role)) {
    return <NotAuthorized />
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return user ? (
          component
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}
