import { useEffect, useState } from 'react'

export interface ITitle {
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

export const useTitle = (): ITitle => {
  const [title, setTitle] = useState('Skila')

  useEffect(() => {
    document.title = title
  }, [title])

  return {
    setTitle,
    title,
  }
}
