import { ApolloLink } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { getHost } from '../utils'

export const GRAPH_API_URL = `${getHost()}/api/graphql`

// BUG with TS definition - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
export const link: any = createUploadLink({
  uri: GRAPH_API_URL,
  credentials: 'include',
})
