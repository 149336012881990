import { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthenticationContext'
import { getHost } from '../utils'

export type Metadata = {
  favicon: string | null
  logo: string | null
  herologo: string | null
  companyId: string
}

export interface IMetadata {
  metadata: Metadata
  refetchMetadata: () => void
  loading: boolean
  error: Error | null
}

export const emptyMetadata: Metadata = {
  favicon: '',
  logo: '',
  herologo: '',
  companyId: '',
}

export const useMetadataContext = (): IMetadata => {
  const [metadata, setMetadata] = useState(emptyMetadata)
  const { user } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchMetadata = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${getHost()}/api/meta`)
      const { data } = await response.json()

      setMetadata(data as Metadata)
    } catch (e: any) {
      setError(e as Error)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchMetadata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return {
    refetchMetadata: fetchMetadata,
    loading,
    error,
    metadata,
  }
}
