import { createContext, useContext } from 'react'
import { ISettings, useSettingsContext } from '../hooks/useSettings'
import { emptySettings } from '../layout/authenticated/settings/rows/CompanyRow/helpers'

const SettingsContext = createContext<ISettings>({
  settings: emptySettings,
  refetchSettings: () => {},
  loading: true,
  error: undefined,
})

export const useSettings = () => {
  return useContext(SettingsContext)
}

interface IProps {
  children: React.ReactNode
}

export const SettingsContextProvider = ({ children }: IProps) => {
  const { settings, refetchSettings, loading, error } = useSettingsContext()

  return (
    <SettingsContext.Provider
      value={{ settings, refetchSettings, loading, error }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
