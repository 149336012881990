import { createContext, useContext } from 'react'
import { ITitle, useTitle } from '../hooks/useTitle'

const TitleContext = createContext<ITitle>({
  title: '',
  setTitle: () => {},
})

export const usePageTitle = () => {
  return useContext(TitleContext)
}

interface IProps {
  children: React.ReactNode
}

export const TitleContextProvider = ({ children }: IProps) => {
  const { title, setTitle } = useTitle()

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  )
}
