import Divider from '@mui/material/Divider'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import SettingsIcon from '@mui/icons-material/Settings'
import Toolbar from '@mui/material/Toolbar'
import { Box } from '@mui/system'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import HomeIcon from '@mui/icons-material/Home'
import GroupIcon from '@mui/icons-material/Group'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { useHistory, useLocation } from 'react-router'
import { Logo } from './Logo'
import { Grid } from '@mui/material'
import GroupsIcon from '@mui/icons-material/Groups'
import { useAuth } from '../contexts/AuthenticationContext'
import EmailIcon from '@mui/icons-material/Email'

export const DrawerMenu = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { user } = useAuth()

  const setSelected = (path: string) => {
    if (pathname === path) {
      return true
    }
    if (pathname.includes(path) && path !== '/') {
      return true
    }
    return false
  }

  return (
    <Box>
      <Toolbar>
        <Grid container justifyContent="center" sx={{ height: 36 }}>
          <Logo type="logo-normal" />
        </Grid>
      </Toolbar>
      <Divider />
      {/* <List>
        <ListItem
          selected={setSelected('/')}
          onClick={() => history.push('/')}
          button
          key={'Home'}
        >
          <ListItemIcon>
            <HomeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={'Home'} />
        </ListItem>
      </List>
      <Divider /> */}
      <List>
        <ListItem
          selected={setSelected('/ship')}
          onClick={() => history.push('/ship')}
          button
          key={'Ship'}
        >
          <ListItemIcon>
            <DoubleArrowIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={'Ship'} />
        </ListItem>
        <ListItem
          selected={setSelected('/return')}
          onClick={() => history.push('/return')}
          button
          key={'RMA List'}
        >
          <ListItemIcon>
            <DoubleArrowIcon
              color="primary"
              sx={{ transform: `rotate(180deg)` }}
            />
          </ListItemIcon>
          <ListItemText primary={'Return'} />
        </ListItem>
        <ListItem
          selected={setSelected('/company-location')}
          onClick={() => history.push('/company-location')}
          button
          key={'Company location'}
        >
          <ListItemIcon>
            <HomeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={'Company location'} />
        </ListItem>
        <ListItem
          selected={setSelected('/customer-location')}
          onClick={() => history.push('/customer-location')}
          button
          key={'Customer location'}
        >
          <ListItemIcon>
            <LocalShippingIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={'Customer location'} />
        </ListItem>
        <ListItem
          selected={setSelected('/products')}
          onClick={() => history.push('/products')}
          button
          key={'Products'}
        >
          <ListItemIcon>
            <ShoppingCartIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={'Products'} />
        </ListItem>
        <ListItem
          selected={setSelected('/users')}
          onClick={() => history.push('/users')}
          button
          key={'Users'}
        >
          <ListItemIcon>
            <GroupsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={'Users'} />
        </ListItem>
        <ListItem
          selected={setSelected('/emails')}
          onClick={() => history.push('/emails')}
          button
          key={'Emails'}
        >
          <ListItemIcon>
            <EmailIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={'Emails'} />
        </ListItem>
        {user?.role === 'ADMIN' && (
          <ListItem
            selected={setSelected('/settings')}
            onClick={() => history.push('/settings')}
            button
            key={'Settings'}
          >
            <ListItemIcon>
              <SettingsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={'Settings'} />
          </ListItem>
        )}
      </List>
    </Box>
  )
}
