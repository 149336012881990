import { Box, BoxProps } from '@mui/system'
import { useState } from 'react'

interface IProps {
  alt?: string
  boxProps?: BoxProps
  src: string
  backup?: JSX.Element
}

export const Image = ({ src, alt, boxProps, backup }: IProps) => {
  const [notLoaded, setNotLoaded] = useState(false)

  if (notLoaded && backup) {
    return backup
  }
  return (
    <Box
      {...boxProps}
      component="img"
      alt={alt ? alt : 'Image component'}
      src={src}
      onError={(event: any) => {
        event.target.style.display = 'none'
        setNotLoaded(true)
      }}
    />
  )
}
