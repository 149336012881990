import { ApolloError, useQuery } from '@apollo/client'
import { useEffect, useReducer, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { GET_LOGGED_IN_USER } from '../apollo/queries'
import { User } from '../types/user'
import { getHost } from '../utils'
import { useImperativeLazyQuery } from './useLazyQuery'
import { useURLQuery } from './useURLQuery'

export interface ILogin {
  loading: boolean
  user: User | null
  error: null | ApolloError | Error
  login: ({ email, password, token }: ILoginFunc, cb?: () => void) => void
  relog: ({ email, password, token }: ILoginFunc, cb?: () => void) => void
  logout: () => void
  sendMagicLink: (email: string) => Promise<Response>
  refetch: any
  initial: boolean
}

interface ILoginFunc {
  email?: string
  password?: string
  token?: string
}

interface IState {
  user: null | User
  loading: boolean
  error: null | ApolloError | Error
}

const initialState: IState = {
  user: null,
  loading: true,
  error: null,
}

type TAction =
  | { type: 'loading'; data: boolean }
  | { type: 'success'; data: User | null }
  | { type: 'error'; error: ApolloError | Error }

const reducer = (state: IState, action: TAction) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: action.data,
      }
    case 'success':
      return {
        ...state,
        user: action.data,
        loading: false,
        error: null,
      }
    case 'error':
      return {
        ...state,
        user: null,
        loading: false,
        error: action.error,
      }
    default:
      return initialState
  }
}

const LOGIN_URL = `${getHost()}/api/login`
const LOGOUT_URL = `${getHost()}/api/logout`
const MAGIC_LINK_URL = `${getHost()}/api/login-link`

export const useLogin = (): ILogin => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [initial, setInitial] = useState(false)
  const checkUser = useImperativeLazyQuery(GET_LOGGED_IN_USER)
  const { error, data, refetch } = useQuery(GET_LOGGED_IN_USER)
  const { pathname, search } = useLocation()
  const history = useHistory()
  const query = useURLQuery()

  /*
    Handle possible token URL first then continue as normally.
    Check if the user is signed in or not and set state accordingly.
    Keeps track of the Graphql query above.
  */
  useEffect(() => {
    if (state.loading && initial) return

    // Important, complements the useEffect in App.tsx and handles redirection
    if (pathname.startsWith('/login/token/')) {
      const token = pathname.replace('/login/token/', '')
      const rmaId = query.get('rmaId')
      const orderId = query.get('orderId')

      if (rmaId && rmaId.trim() !== '') {
        history.replace(`/login?rmaId=${rmaId || ''}`)
      } else if (orderId && orderId.trim() !== '') {
        history.replace(`/login?orderId=${orderId || ''}`)
      } else {
        history.replace(`/login`)
      }

      relog({ token })
    } else {
      if (error) {
        !initial && setInitial(true)
        console.log(error.message)
        return dispatch({ type: 'loading', data: false })
      }
      if (data) {
        !initial && setInitial(true)
        return dispatch({ type: 'success', data: data.user })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, data])

  const sendMagicLink = async (email: string) => {
    return fetch(MAGIC_LINK_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
  }

  const login = async (
    { email, password, token }: ILoginFunc,
    cb?: () => void,
  ) => {
    dispatch({ type: 'loading', data: true })
    try {
      let response
      if (token) {
        response = await fetch(`${LOGIN_URL}/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
      } else {
        response = await fetch(LOGIN_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            email,
            password,
          }),
        })
      }

      const data = await response.json()

      if (data.errors) {
        if (data.errors.api) {
          return dispatch({
            type: 'error',
            error: Error(data.errors.api),
          })
        }
        return dispatch({
          type: 'error',
          error: Error(
            'An error occured. Please, check your credentials and try again.',
          ),
        })
      }

      const { data: user } = await checkUser({ fetchPolicy: 'network-only' })
      dispatch({ type: 'success', data: user.user })
      cb && cb()
    } catch (e: any) {
      console.error(e.message)
      dispatch({
        type: 'error',
        error: Error('Something went wrong, the server could not be reached.'),
      })
    }
  }

  const logout = async () => {
    dispatch({ type: 'loading', data: true })
    try {
      const response = await fetch(LOGOUT_URL, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      })

      const { logout } = await response.json()

      if (logout === 'success') {
        setInitial(true)
        return dispatch({ type: 'success', data: null })
      }
      throw new Error('Something went wrong when we tried to log you out.')
    } catch (e: any) {
      dispatch({ type: 'error', error: e })
    }
  }

  const relog = async (loginInfo: ILoginFunc) => {
    state.user && (await logout())
    await login({ ...loginInfo })
  }

  return {
    ...state,
    login,
    logout,
    refetch,
    initial,
    relog,
    sendMagicLink,
  }
}
