import { useAuth } from '../contexts/AuthenticationContext'
import { useMetadata } from '../contexts/MetadataProvider'
import { useSettings } from '../contexts/SettingsContext'
import { BigLoading } from './Loading'

type Props = {
  children: JSX.Element
}

/*
  This component loods at other loading states and renders the app once everything is actually loaded
*/
export const ApplicationLoader = ({ children }: Props) => {
  const { loading: metadataLoading } = useMetadata()
  const { initial: initialUserLoading } = useAuth()
  const { loading: settingsLoading } = useSettings()

  if (metadataLoading || !initialUserLoading || settingsLoading) {
    return <BigLoading />
  }

  return children
}
