import { ApolloError, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_SETTINGS } from '../apollo/queries'
import { useAuth } from './../contexts/AuthenticationContext'
import {
  emptySettings,
  settingsMapper,
} from '../layout/authenticated/settings/rows/CompanyRow/helpers'
import { AppSettings, ISettingsObject } from '../types/settings'

export interface ISettings {
  settings: ISettingsObject
  refetchSettings: () => void
  loading: boolean
  error: ApolloError | undefined
}

export const useSettingsContext = (): ISettings => {
  const [settings, setSettings] = useState(emptySettings)
  const { user } = useAuth()

  const { data, loading, error, refetch } = useQuery<
    { appSettings: AppSettings[] } | undefined
  >(GET_SETTINGS, { fetchPolicy: 'network-only' })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (data && data.appSettings) {
      setSettings({ ...settingsMapper(data.appSettings) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    refetchSettings: refetch,
    loading,
    error,
    settings,
  }
}
