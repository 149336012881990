import { useEffect } from 'react'

// This is a snippet from hotjars website, I just extracted the relevant code
function hotjar(h: any, o: any, t: any, j: any, a: any, r: any, id: number) {
  h.hj =
    h.hj ||
    function () {
      ;(h.hj.q = h.hj.q || []).push(arguments)
    }
  h._hjSettings = { hjid: id, hjsv: 6 }
  a = o.getElementsByTagName('head')[0]
  r = o.createElement('script')
  r.async = 1
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
  a.appendChild(r)
}

export default function startHotjar() {
  // Extract this to .env in the future
  // if (!process.env.REACT_APP_HOTJAR_ID || process.env.NODE_ENV !== 'production')
  //   return

  if (process.env.NODE_ENV !== 'production') return

  // console.log(
  //   `Production environment detected and Hotjar ID present. Starting up Hotjar with ID: ${process.env.REACT_APP_HOTJAR_ID}...`,
  // )

  console.log(
    `Production environment detected and Hotjar ID present. Starting up Hotjar with ID: 2763151...`,
  )

  hotjar(
    window,
    document,
    'https://static.hotjar.com/c/hotjar-',
    '.js?sv=',
    undefined,
    undefined,
    // parseInt(process.env.REACT_APP_HOTJAR_ID),
    2796049, // VISIONECT HARDCODED FOR NOW
  )
}

export const HotjarProvider = () => {
  useEffect(() => {
    /*
    Starts Hotjar only if in production and
    process.env.HOTJAR_ID is available.
  */
    startHotjar()
  }, [])

  return null
}
