import { Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import { AGENT_HOMEPAGE_PATH, USER_HOMEPAGE_PATH } from '../App'
import { useAuth } from '../contexts/AuthenticationContext'
import { Button } from './Button'

export const NotAuthorized = () => {
  const { logout, user } = useAuth()
  const history = useHistory()

  const handleLogout = () => {
    logout()
    history.push('/login')
  }

  const handleHome = () => {
    if (user?.role === 'USER') {
      history.push(USER_HOMEPAGE_PATH)
    } else {
      history.push(AGENT_HOMEPAGE_PATH)
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      pt={10}
      spacing={1}
    >
      <Grid item xs={12} container alignItems="center" justifyContent="center">
        <Typography>Could not find what you're looking for.</Typography>
      </Grid>
      <Grid item xs={12} container alignItems="center" justifyContent="center">
        <Button variant="contained" onClick={handleHome}>
          Go home
        </Button>
        <Button onClick={handleLogout}>Log out</Button>
      </Grid>
    </Grid>
  )
}
