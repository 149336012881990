import {
  CircularProgress,
  CircularProgressProps,
  Divider,
  Grid,
  GridProps,
  Typography,
} from '@mui/material'
import { Box, styled, TypographyProps } from '@mui/system'
import { keyframes } from '@mui/styled-engine'
import { Logo } from './Logo'

const ANIMATION_PROPS = '4s linear infinite'

const rotateAnimation = keyframes`
from { transform: rotateY(0deg) rotateX(720deg) rotateZ(0deg); }
to { transform: rotateY(360deg) rotateX(0deg) rotateZ(360deg); }
`

const RotateContainer = styled('div')(({ theme }) => {
  return {
    animation: `${rotateAnimation} ${ANIMATION_PROPS}`,
  }
})

interface IWrapper extends GridProps {
  vh?: boolean
}

const Wrapper = styled(Grid, {
  shouldForwardProp: (prop) => !['vh'].includes(prop as string),
})<IWrapper>(({ theme, vh }) => ({
  height: vh ? '100vh' : '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
}))

export const AbsoluteWrapper = styled(Grid)<GridProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
}))

export const Loading = () => {
  return (
    <Wrapper container item>
      <CircularProgress />
    </Wrapper>
  )
}

export const AbsoluteLoading = () => {
  return (
    <AbsoluteWrapper>
      <Wrapper container item>
        <CircularProgress />
      </Wrapper>
    </AbsoluteWrapper>
  )
}

export const BigLoading = () => {
  return (
    <Wrapper container item vh flexDirection="column">
      <RotateContainer>
        <Logo type="logo-letter" width="300px" height="300px" />
      </RotateContainer>
      {/* <Box sx={{ margin: '2rem' }} />
      <Typography>We're preparing the app for you...</Typography> */}
    </Wrapper>
  )
}
