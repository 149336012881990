import { createContext, useContext } from 'react'
import { ILogin, useLogin } from '../hooks/useLogin'

const AuthenticationContext = createContext<ILogin>({
  loading: true,
  user: null,
  error: null,
  login: () => {},
  relog: () => {},
  logout: () => {},
  refetch: () => {},
  sendMagicLink: () => new Promise(() => {}),
  initial: false,
})

export const useAuth = () => {
  return useContext(AuthenticationContext)
}

interface IProps {
  children: React.ReactNode
}

export const AuthenticationContextProvider = ({ children }: IProps) => {
  const {
    login,
    logout,
    loading,
    error,
    user,
    refetch,
    initial,
    relog,
    sendMagicLink,
  } = useLogin()

  return (
    <AuthenticationContext.Provider
      value={{
        login,
        logout,
        loading,
        error,
        user,
        refetch,
        initial,
        relog,
        sendMagicLink,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
