import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  useApolloClient,
  useLazyQuery as useGQLLazyQuery,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'

export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>,
): [
  (options?: QueryLazyOptions<TVariables>) => void,
  LazyQueryResult<TData, TVariables>,
] {
  const result = useGQLLazyQuery(query, options)
  const { enqueueSnackbar } = useSnackbar()
  const { loading, error } = result[1]

  useEffect(() => {
    error && !loading && enqueueSnackbar(error.message, { variant: 'error' })
  }, [error, loading, enqueueSnackbar])

  return result
}

export function useImperativeLazyQuery<
  TData = any,
  TVariables = OperationVariables | undefined,
>(query: DocumentNode) {
  const client = useApolloClient()
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        ...variables,
      }),
    [client],
  )
}
