import gql from 'graphql-tag'
import { ORDER, SHIP_OUT } from '../fragments'

export const GET_USERS_QUERY = gql`
  query GetUsers(
    $limit: Int
    $email: String
    $userId: [BigInt]
    $role: [Role]
  ) {
    users(
      limit: $limit
      filter: { email: $email, userId: $userId, role: $role }
    ) {
      userId
      email
      firstName
      lastName
      role
      isActive
      isStaff
      lastLogin
      dateJoined
      termsAccepted
      customerDetails {
        customerDetailsId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        contactEmail
      }
      pickupLocations {
        pickupLocationId
        userId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
      }
    }
  }
`

export const GET_LOGGED_IN_USER = gql`
  query GetUser {
    user {
      userId
      email
      firstName
      lastName
      role
      isActive
      isStaff
      lastLogin
      dateJoined
      termsAccepted
      customerDetails {
        customerDetailsId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
      }
      pickupLocations {
        pickupLocationId
        userId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
      }
    }
  }
`

export const GET_RMAS = gql`
  query GetRmas(
    $limit: Int
    $offset: Int
    $filter: RmaFilter
    $search: String
  ) {
    rmas(limit: $limit, offset: $offset, filter: $filter, search: $search) {
      rmaId
      createdAt
      status
      createdBy {
        modifier {
          email
          firstName
          lastName
        }
      }
      currierSetting {
        currierSettingId
        title
        currierService
        createdAt
        deletedAt
      }
      user {
        userId
        email
        firstName
        lastName
      }
      shippingLocation {
        companyName
        secondaryAddress
        country
        state
        phone
      }
      pickupLocation {
        companyName
        secondaryAddress
        country
        state
        phone
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
      }
      rmaProducts {
        productId
        quantity
        price
        serialNumber
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          ean
          sku
          tariffNumber
        }
      }
      rmaState {
        rmaStateId
        state
      }
      returnReason {
        returnReasonId
        reason
      }
      rmaDocuments {
        public
        rmaDocumentId
        rmaId
        name
        path
        createdAt
      }
      title
      pickupStart
      pickupEnd
    }
  }
`

export const GET_RMA_STATES = gql`
  query GetRmaStates {
    rmaStates {
      rmaStateId
      state
    }
  }
`

export const GET_RMA_RETURN_REASONS = gql`
  query GetReturnReasons {
    returnReasons {
      returnReasonId
      reason
    }
  }
`

export const GET_PICKUP_LOCATIONS = gql`
  query GetPickupLocations($userId: [BigInt]) {
    pickupLocations(filter: { userId: $userId }) {
      pickupLocationId
      userId
      companyName
      address
      secondaryAddress
      zip
      city
      country
      state
      phone
    }
  }
`

export const GET_MESSAGES = gql`
  query GetRmaComments($rmaId: ID!) {
    rmaComments(rmaId: $rmaId) {
      rmaCommentId
      rmaId
      userId
      visibility
      user {
        email
        firstName
        lastName
        role
      }
      comment
      read
      createdAt
      deletedAt
      modificationTime
    }
  }
`

export const GET_SHIP_MESSAGES = gql`
  query GetRmaComments($rmaId: ID!) {
    rmaComments(rmaId: $rmaId) {
      rmaCommentId
      rmaId
      userId
      visibility
      user {
        email
        firstName
        lastName
        role
      }
      comment
      read
      createdAt
      deletedAt
      modificationTime
    }
  }
`

export const GET_SHIPPING_LOCATIONS = gql`
  query GetShippingLocations {
    shippingLocations {
      shippingLocationId
      companyName
      address
      secondaryAddress
      zip
      city
      country
      state
      phone
    }
  }
`

export const GET_RMA = gql`
  query GetRma($rmaId: ID!) {
    changeLog(entityId: $rmaId, model: Rma) {
      entityId
      modifierId
      model
      action
      data
      modifier {
        email
        firstName
        lastName
      }
      modificationTime
    }
    rma(rmaId: $rmaId) {
      rmaId
      createdAt
      status
      createdBy {
        modifier {
          email
          firstName
          lastName
        }
      }
      currierSetting {
        currierSettingId
        title
        currierService
        createdAt
        deletedAt
      }
      user {
        userId
        email
        firstName
        lastName
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
      }
      shippingLocation {
        shippingLocationId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
      }
      rmaProducts {
        productId
        quantity
        price
        currency
        serialNumber
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          sku
          ean
          tariffNumber
        }
      }
      pickupLocation {
        pickupLocationId
        companyName
        secondaryAddress
        country
        state
        phone

        address
        city
        zip
      }
      rmaState {
        rmaStateId
        state
      }
      returnReason {
        returnReasonId
        reason
      }
      rmaDocuments {
        public
        rmaDocumentId
        rmaId
        name
        path
        createdAt
      }
      title
      pickupStart
      pickupEnd
    }
  }
`

export const GET_RMA_USER = gql`
  query GetRma($rmaId: ID!) {
    rma(rmaId: $rmaId) {
      rmaId
      status
      createdBy {
        modifier {
          email
          firstName
          lastName
        }
      }
      currierSetting {
        currierSettingId
        title
        currierService
        createdAt
        deletedAt
      }
      user {
        userId
        email
        firstName
        lastName
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
      }
      shippingLocation {
        shippingLocationId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
      }
      rmaProducts {
        productId
        quantity
        price
        serialNumber
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
        }
      }
      pickupLocation {
        pickupLocationId
        companyName
        secondaryAddress
        country
        state
        phone

        address
        city
        zip
      }
      rmaState {
        rmaStateId
        state
      }
      returnReason {
        returnReasonId
        reason
      }
      rmaDocuments {
        public
        rmaDocumentId
        rmaId
        name
        path
        createdAt
      }
      title
      pickupStart
      pickupEnd
    }
  }
`

export const GET_PRODUCTS = gql`
  query GetProducts($filter: ProductFilter) {
    products(filter: $filter) {
      productId
      name
      price
      currency
      status
      serialNumber
      weight
      width
      height
      depth
      identificationNumber
      tariffNumber
      sku
      ean
    }
  }
`

export const GET_PRODUCT = gql`
  query GetProduct($productId: BigInt!) {
    product(productId: $productId) {
      productId
      name
      price
      currency
      status
      serialNumber
      weight
      width
      height
      depth
      identificationNumber
      tariffNumber
      sku
      ean
      productLabels {
        productLabelId
        productId
        productLabel {
          label
          serviceCode
          contentId
          unCode
        }
      }
    }
  }
`

export const GET_SETTINGS = gql`
  query GetSettings {
    appSettings {
      key
      value
    }
  }
`

export const GET_PRODUCT_LABELS = gql`
  query GetProductLabels {
    productLabels {
      productLabelId
      label
      serviceCode
      contentId
      unCode
    }
  }
`

export const GET_CURRIER_SETTING = gql`
  query CurrierShipmentTracking($currierSettingId: BigInt!) {
    currierSetting(filter: { currierSettingId: $currierSettingId }) {
      currierSettingId
      label
      serviceCode
      contentId
      unCode
    }
  }
`

export const GET_CURRIER_SETTINGS = gql`
  query CurrierShipmentTracking($currierService: [CurrierService]) {
    currierSettings(filter: { currierService: $currierService }) {
      currierSettingId
      title
      currierService
      data
      deletedAt
    }
  }
`

export const GET_EMAIL_TEMPLATES = gql`
  query EmailTemplates {
    emailTemplates {
      emailTemplateId
      type
      entityId
      language
      template
      html
      text
      subject
    }
  }
`

export const GET_EMAIL_TEMPLATE = gql`
  query EmailTemplate($emailTemplateId: BigInt!) {
    emailTemplate(emailTemplateId: $emailTemplateId) {
      emailTemplateId
      type
      entityId
      language
      template
      html
      text
      subject
    }
  }
`

export const CHECK_VAT_VALIDITY = gql`
  query CheckVatValidity($countryCode: CountryCode!, $vatNumber: String!) {
    checkVatValidity(countryCode: $countryCode, vatNumber: $vatNumber) {
      countryCode
      vatNumber
      requestDate
      valid
      name
      address
    }
  }
`

export const GET_SHIPMENT_TRACKING = gql`
  query CurrierShipmentTracking($currierId: BigInt!) {
    currierShipmentTracking(filter: { currierId: $currierId }) {
      id
      origin {
        address {
          addressLocality
        }
        servicePoint {
          url
          label
        }
      }
      destination {
        address {
          addressLocality
        }
        servicePoint {
          url
          label
        }
      }
      status {
        timestamp
        location {
          address {
            addressLocality
          }
          servicePoint {
            url
            label
          }
        }
        description
        statusCode
        status
        remark
        nextSteps
      }
      details {
        proofOfDelivery {
          signatureUrl
          documentUrl
        }
        proofOfDeliverySignedAvailable
        totalNumberOfPieces
        pieceIds
      }
      events {
        timestamp
        location {
          address {
            addressLocality
          }
          servicePoint {
            url
            label
          }
        }
        statusCode
        status
        description
        pieceIds
        remark
        nextSteps
      }
      estimatedTimeOfDeliveryRemark
      estimatedDeliveryTimeFrame {
        estimatedFrom
        estimatedThrough
      }
    }
  }
`

export const GET_CHANGELOG = gql`
  query ChangeLog($entityId: ID!, $model: ChangeLogModel!) {
    changeLog(entityId: $entityId, model: $model) {
      entityId
      modifierId
      model
      action
      data
      modifier
      modificationTime
    }
  }
`

export const GET_ORDER = gql`
  query GetOrder(
    $orderId: BigInt
    $name: String
    $externalEmail: String
    $externalOrderId: ID
  ) {
    order(
      orderId: $orderId
      name: $name
      externalEmail: $externalEmail
      externalOrderId: $externalOrderId
    ) {
      createdBy {
        modifier {
          email
          firstName
          lastName
        }
      }
      orderDocuments {
        public
        orderDocumentId
        name
        path
        createdAt
        deletedAt
      }
      customerLocation {
        pickupLocationId
        userId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      companyLocation {
        shippingLocationId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      orderId
      user {
        userId
        email
        firstName
        lastName
      }
      shop
      name
      price
      discount
      currency
      tax
      status
      externalOrderId
      orderItems {
        pendingQuantity
        shippedQuantity
        shipStatus
        orderItemId
        name
        quantity
        discount
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          sku
          serialNumber
          weight
          width
          height
          depth
          ean
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
      }
      externalOrderId
      externalOrderNumber
      externalCustomerId
      externalEmail
      externalCreatedAt
      externalClosedAt
    }
  }
`

export const GET_ORDER_AND_CHANGELOG = gql`
  query GetOrder(
    $orderId: BigInt
    $orderIdInternal: ID!
    $name: String
    $externalEmail: String
    $externalOrderId: ID
  ) {
    changeLog(entityId: $orderIdInternal, model: Order) {
      entityId
      modifierId
      model
      action
      data
      modifier {
        email
        firstName
        lastName
      }
      modificationTime
    }
    order(
      orderId: $orderId
      name: $name
      externalEmail: $externalEmail
      externalOrderId: $externalOrderId
    ) {
      createdBy {
        modifier {
          email
          firstName
          lastName
        }
      }
      orderDocuments {
        public
        orderDocumentId
        name
        path
        createdAt
        deletedAt
      }
      customerLocation {
        pickupLocationId
        userId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      companyLocation {
        shippingLocationId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      orderId
      user {
        userId
        email
        firstName
        lastName
      }
      shop
      name
      price
      discount
      currency
      tax
      status
      externalOrderId
      orderItems {
        pendingQuantity
        shippedQuantity
        shipStatus
        orderItemId
        name
        quantity
        discount
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          sku
          serialNumber
          weight
          width
          height
          depth
          ean
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
      }
      externalOrderId
      externalOrderNumber
      externalCustomerId
      externalEmail
      externalCreatedAt
      externalClosedAt
    }
  }
`

export const GET_ORDERS = gql`
  query GetOrders($limit: Int, $filter: OrderFilter, $search: String) {
    orders(limit: $limit, filter: $filter, search: $search) {
      createdBy {
        modifier {
          email
          firstName
          lastName
        }
      }
      orderDocuments {
        public
        orderDocumentId
        name
        path
        createdAt
        deletedAt
      }
      userId
      customerLocation {
        pickupLocationId
        userId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      companyLocation {
        shippingLocationId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      orderId
      user {
        userId
        email
        firstName
        lastName
      }
      shop
      name
      price
      discount
      currency
      tax
      status
      externalOrderId
      orderItems {
        pendingQuantity
        shippedQuantity
        shipStatus
        orderItemId
        name
        quantity
        discount
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          sku
          serialNumber
          weight
          width
          height
          depth
          ean
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
      }
      externalOrderId
      externalOrderNumber
      externalCustomerId
      externalEmail
      externalCreatedAt
      externalClosedAt
    }
  }
`

export const GET_SHIP_OUT = gql`
  query GetShipOut($shipOutId: ID!) {
    shipOut(shipOutId: $shipOutId) {
      shipOutId
      orderId
      error {
        code
        title
        message
      }
      shipOutMessage {
        shipOutMessageId
        message
        createdAt
      }
      user {
        userId
        email
        firstName
        lastName
        deletedAt
      }
      currierSetting {
        currierSettingId
        title
        currierService
        data
        createdAt
        deletedAt
      }
      shipOutProducts {
        shipOutProductId
        productId
        quantity
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
        serialNumber
      }
      title
      state
      status
      createdAt
      pickupAt
      shipOutDocuments {
        shipOutDocumentId
        name
        path
        createdAt
        deletedAt
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
        deletedAt
      }
      deletedAt
    }
  }
`

export const GET_SHIP_OUTS = gql`
  query GetShipOuts($filter: ShipOutFilter) {
    shipOuts(filter: $filter) {
      shipOutId
      orderId
      error {
        code
        title
        message
      }
      shipOutMessage {
        shipOutMessageId
        message
        createdAt
      }
      shipOutDocuments {
        shipOutDocumentId
        name
        path
        createdAt
        deletedAt
      }
      user {
        userId
        email
        firstName
        lastName
        deletedAt
      }
      currierSetting {
        currierSettingId
        title
        currierService
        data
        createdAt
        deletedAt
      }
      shipOutProducts {
        shipOutProductId
        productId
        quantity
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
        serialNumber
      }
      title
      state
      status
      createdAt
      pickupAt
      shipOutDocuments {
        shipOutDocumentId
        name
        path
        createdAt
        deletedAt
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
        deletedAt
      }
      deletedAt
    }
  }
`

export const GET_SHIP_OUTS_ERRORS = gql`
  query GetShipOuts($filter: ShipOutFilter) {
    shipOuts(filter: $filter) {
      shipOutId
      title
      error {
        code
        title
        message
      }
      shipOutMessage {
        shipOutMessageId
        message
        createdAt
      }
    }
  }
`

export const GET_API_TOKEN = gql`
  query ApiToken($apiTokenId: ID!) {
    apiToken(apiTokenId: $apiTokenId) {
      apiTokenId
      userId
      type
      expires
    }
  }
`

export const GET_API_TOKENS = gql`
  query ApiTokens($userId: BigInt, $limit: Int) {
    apiTokens(limit: $limit, filter: { userId: $userId }) {
      apiTokenId
      userId
      type
      expires
    }
  }
`
