import { Tooltip } from '@mui/material'
import { LoadingButton as MuiButton } from '@mui/lab'
import { useState } from 'react'

interface IProps {
  loading?: boolean
  children: JSX.Element | string
  [x: string]: any
}

export const Button = ({
  loading,
  children,
  textToCopy,
  onClick,
  ...rest
}: IProps) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const copyText = (value: string) => {
    navigator.clipboard.writeText(value)
    setShowTooltip(true)
  }

  return (
    <Tooltip
      open={showTooltip}
      title={'Copied to clipboard!'}
      leaveDelay={800}
      onClose={() => setShowTooltip(false)}
    >
      <MuiButton
        {...rest}
        loading={loading}
        onClick={textToCopy ? () => copyText(textToCopy) : onClick}
      >
        {children}
      </MuiButton>
    </Tooltip>
  )
}
