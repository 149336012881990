import { Typography } from '@mui/material'
import moment from 'moment'

export const createNumberArray = (value: number) => {
  return Array.from(Array(value).keys())
}

export const getHost = () => {
  return process.env.NODE_ENV !== 'production'
    ? process.env.REACT_APP_BASE_URL || ''
    : ''
}

/*
  Currently not used. You can use this instead of new-password in input fields,
  hopefully it stops Chrome from autofilling stuff. This could come in handy
  in some cases.
*/
export const godDamnAutocompletePissingMeOff = (len: number) => {
  const godDamnit = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const godDamnitLength = godDamnit.length

  return createNumberArray(len).reduce<string>((previousValue: string) => {
    return (
      previousValue +
      godDamnit.charAt(Math.floor(Math.random() * godDamnitLength))
    )
  }, '')
}

export const isTomorrowWeekend = () => {
  const tomorrow = moment().add(1, 'day')

  if (tomorrow.day() === 6 || tomorrow.day() === 0) {
    return true
  }
  return false
}

export const parsePhone = (num: string) => {
  if (num.startsWith('0')) {
    return num.slice(1, num.length)
  }
  return num
}

export const convertToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

/*
  Only used on one place, to display source code for email creation
  while we're still doing it. Can remove later.
*/
export const escapeHTML = (str: string) => {
  const htmlEscapes: any = {
    '&': '&amp',
    '<': '&lt',
    '>': '&gt',
    '"': '&quot',
    "'": '&#39',
  }

  const reUnescapedHtml = /[&<>"']/g
  const reHasUnescapedHtml = RegExp(reUnescapedHtml.source)

  return str && reHasUnescapedHtml.test(str)
    ? str.replace(reUnescapedHtml, (chr: any) => htmlEscapes[chr])
    : str
}

export const formatDHLErrorMessage = (
  message: string | string[],
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
    | undefined = 'body1',
) => {
  try {
    message = JSON.parse(message as string)
  } catch (e: any) {
    console.log('Could not parse error message. Will proceed.')
  }

  if (typeof message === 'string') {
    return <Typography variant={variant}>{message}</Typography>
  }

  return message.map((error: string) => {
    return (
      <Typography variant={variant} component="div" m={1}>
        {error}
      </Typography>
    )
  })
}
