import { AppSettings, ISettingsObject } from '../../../../../types/settings'

/*
  We don't allow users to delete files that have been created
  by us - battery label, PI and the waybill for now. Extend this
  function if we need to protect more files in the future.
*/
const PROTECTED_FILES = [
  'label.pdf',
  'proforma-invoice.pdf',
  /battery-label-[0-9]*.pdf/g,
]
export const isFileProtected = (name: string) => {
  return PROTECTED_FILES.find((f: string | RegExp) => {
    return name.match(f)
  })
    ? true
    : false
}

export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const createCurrierLink = (currier: 'DHL', tracking: string) => {
  return `https://www.dhl.com/si-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking}`
}

export const emptySettings: ISettingsObject = {
  Favicon: '',
  Logo: '',
  HeroLogo: '',
  FontColor: '',
  InfoName: '',
  FinanceEmail: '',
  FinanceName: '',
  RepairEmail: '',
  RepairName: '',
  SupportEmail: '',
  SenderInfoName: '',
  SenderInfoEmail: '',
  SenderInfoEmailId: '',
  SenderInfoEmailVerified: '',
  CompanyInfoEmail: '',
  SupportName: '',
  CompanyName: '',
  CompanyAddress: '',
  CompanyCity: '',
  CompanyZip: '',
  CompanyCountry: '',
  CompanyCurrency: '',
  CompanyState: '',
  CompanyVat: '',
  CompanyPhone: '',
  RmaCommentPublic: '0',
  ChatWidgetEmbeddedHTML: '',
  ShopifyStoreUrl: '',
  ShopifyStoreActive: '',
  DHLAccountNumber: '',
  DHLUsername: '',
  DHLPassword: '',
  DHLAPITrackingKey: '',
  USPSUsername: '',
  USPSPassword: '',
  USPSMerchantAccountCode: '',
  USPSMID: '',
  USPSAccountNumber: '',
  ReturnAPIToken: '',
}

export const settingsMapper = (settings: AppSettings[]): ISettingsObject => {
  settings.forEach(({ key, value }) => {
    emptySettings[key] = value
  })
  return emptySettings
}

export const settingsDemapper = (settings: ISettingsObject): AppSettings[] => {
  const response: AppSettings[] = []
  Object.entries(settings).map(([key, value]: any) => {
    return response.push({ key, value })
  })
  return response
}
