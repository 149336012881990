import gql from 'graphql-tag'
import { ORDER, SHIP_OUT } from '../fragments'

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $email: String!
    $role: Role!
    $firstName: String
    $lastName: String
    $password: String
    $repeatPassword: String
  ) {
    createUser(
      email: $email
      role: $role
      input: {
        firstName: $firstName
        lastName: $lastName
        password: $password
        repeatPassword: $repeatPassword
      }
    ) {
      email
      userId
      role
      firstName
      lastName
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($userId: BigInt!, $firstName: String, $lastName: String) {
    updateUser(
      userId: $userId
      input: { firstName: $firstName, lastName: $lastName }
    ) {
      email
      userId
      role
      firstName
      lastName
    }
  }
`

export const ACCEPT_TERMS_MUTATION = gql`
  mutation UpdateUser($userId: BigInt!, $termsAccepted: Int) {
    updateUser(userId: $userId, input: { termsAccepted: $termsAccepted }) {
      userId
    }
  }
`

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword(
    $userId: BigInt!
    $password: String
    $repeatPassword: String
  ) {
    updateUser(
      userId: $userId
      input: { password: $password, repeatPassword: $repeatPassword }
    ) {
      email
      userId
      role
      firstName
      lastName
    }
  }
`

export const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole($userId: BigInt!, $role: Role) {
    updateUser(userId: $userId, input: { role: $role }) {
      userId
    }
  }
`

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProduct(
    $name: String!
    $price: Float!
    $currency: CurrencyCode!
    $status: ProductStatus!
    $sku: String
    $weight: Float!
    $width: Int!
    $height: Int!
    $depth: Float!
    $ean: String
    $tariffNumber: String
    $productLabelId: [BigInt]
  ) {
    createProduct(
      name: $name
      price: $price
      currency: $currency
      status: $status
      weight: $weight
      width: $width
      height: $height
      depth: $depth
      input: {
        ean: $ean
        sku: $sku
        tariffNumber: $tariffNumber
        productLabelId: $productLabelId
      }
    ) {
      productId
    }
  }
`

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateProduct(
    $productId: BigInt!
    $name: String
    $price: Float
    $currency: CurrencyCode
    $status: ProductStatus
    $sku: String
    $weight: Float
    $width: Int
    $height: Int
    $depth: Float
    $ean: String
    $tariffNumber: String
    $productLabelId: [BigInt]
  ) {
    updateProduct(
      productId: $productId
      input: {
        name: $name
        price: $price
        currency: $currency
        status: $status
        sku: $sku
        weight: $weight
        width: $width
        height: $height
        depth: $depth
        ean: $ean
        tariffNumber: $tariffNumber
        productLabelId: $productLabelId
      }
    ) {
      productId
    }
  }
`

export const CREATE_CUSTOMER_DETAILS_MUTATION = gql`
  mutation CreateCustomerDetails(
    $userId: BigInt!
    $firstName: String
    $lastName: String
    $address: String!
    $zip: String!
    $city: String!
    $country: CountryCode!
    $companyName: String
    $secondaryAddress: String
    $state: String
    $phone: String
    $contactEmail: String
  ) {
    createCustomerDetails(
      userId: $userId
      address: $address
      zip: $zip
      city: $city
      country: $country
      input: {
        companyName: $companyName
        secondaryAddress: $secondaryAddress
        state: $state
        phone: $phone
        contactEmail: $contactEmail
      }
    ) {
      customerDetailsId
      companyName
      address
      secondaryAddress
      zip
      city
      country
      state
      phone
      contactEmail
    }
    updateUser(
      userId: $userId
      input: { firstName: $firstName, lastName: $lastName }
    ) {
      userId
    }
  }
`

export const UPDATE_CUSTOMER_DETAILS_MUTATION = gql`
  mutation UpdateCustomerDetails(
    $userId: BigInt!
    $firstName: String
    $lastName: String
    $companyName: String
    $address: String
    $secondaryAddress: String
    $zip: String
    $city: String
    $country: CountryCode
    $state: String
    $phone: String
    $contactEmail: String
  ) {
    updateCustomerDetails(
      userId: $userId
      input: {
        address: $address
        companyName: $companyName
        secondaryAddress: $secondaryAddress
        state: $state
        phone: $phone
        zip: $zip
        city: $city
        country: $country
        contactEmail: $contactEmail
      }
    ) {
      customerDetailsId
      companyName
      address
      secondaryAddress
      zip
      city
      country
      state
      phone
      contactEmail
    }
    updateUser(
      userId: $userId
      input: { firstName: $firstName, lastName: $lastName }
    ) {
      userId
    }
  }
`

export const CREATE_PICKUP_MUTATION = gql`
  mutation CreatePickupLocations(
    $userId: BigInt!
    $address: String!
    $zip: String!
    $city: String!
    $country: CountryCode!
    $companyName: String
    $secondaryAddress: String
    $state: String
    $phone: String
  ) {
    createPickupLocations(
      userId: $userId
      address: $address
      zip: $zip
      city: $city
      country: $country
      input: {
        companyName: $companyName
        secondaryAddress: $secondaryAddress
        state: $state
        phone: $phone
      }
    ) {
      pickupLocationId
      userId
      companyName
      address
      secondaryAddress
      zip
      city
      country
      state
      phone
    }
  }
`

export const UPDATE_PICKUP_MUTATION = gql`
  mutation UpdatePickupLocations(
    $pickupLocationId: BigInt!
    $address: String!
    $zip: String!
    $city: String!
    $country: CountryCode!
    $companyName: String
    $secondaryAddress: String
    $state: String
    $phone: String
  ) {
    updatePickupLocations(
      pickupLocationId: $pickupLocationId
      input: {
        address: $address
        companyName: $companyName
        secondaryAddress: $secondaryAddress
        state: $state
        phone: $phone
        zip: $zip
        city: $city
        country: $country
      }
    ) {
      pickupLocationId
      userId
      companyName
      address
      secondaryAddress
      zip
      city
      country
      state
      phone
    }
  }
`

export const CREATE_SHIPPING_MUTATION = gql`
  mutation CreateShippingLocations(
    $address: String!
    $zip: String!
    $city: String!
    $country: CountryCode!
    $companyName: String
    $secondaryAddress: String
    $state: String
    $phone: String
  ) {
    createShippingLocations(
      address: $address
      zip: $zip
      city: $city
      country: $country
      input: {
        companyName: $companyName
        secondaryAddress: $secondaryAddress
        state: $state
        phone: $phone
      }
    ) {
      shippingLocationId
      companyName
      address
      zip
      city
      country
      state
      phone
    }
  }
`

export const UPDATE_SHIPPING_MUTATION = gql`
  mutation UpdateShippingLocations(
    $shippingLocationId: BigInt!
    $address: String!
    $zip: String!
    $city: String!
    $country: CountryCode!
    $companyName: String
    $secondaryAddress: String
    $state: String
    $phone: String
  ) {
    updateShippingLocations(
      shippingLocationId: $shippingLocationId
      input: {
        address: $address
        companyName: $companyName
        secondaryAddress: $secondaryAddress
        state: $state
        phone: $phone
        zip: $zip
        city: $city
        country: $country
      }
    ) {
      shippingLocationId
      companyName
      address
      secondaryAddress
      zip
      city
      country
      state
      phone
    }
  }
`

export const CREATE_RMA_MUTATION = gql`
  mutation CreateRma(
    $userId: BigInt!
    $shippingLocationId: BigInt!
    $rmaStateId: BigInt!
    $pickupLocationId: BigInt
    $title: String
    $returnReasonId: BigInt
    $pickupStart: DateTime
    $pickupEnd: DateTime
    $currierSettingId: BigInt
    $rmaProducts: [CreateRmaProduct]!
  ) {
    createRma(
      userId: $userId
      shippingLocationId: $shippingLocationId
      rmaStateId: $rmaStateId
      rmaProducts: $rmaProducts
      input: {
        pickupLocationId: $pickupLocationId
        title: $title
        returnReasonId: $returnReasonId
        pickupStart: $pickupStart
        pickupEnd: $pickupEnd
        currierSettingId: $currierSettingId
      }
    ) {
      rmaId
      title
      pickupStart
      pickupEnd
      currierSetting {
        currierSettingId
        title
        currierService
        createdAt
        deletedAt
      }
      user {
        userId
        email
        firstName
        lastName
      }
      rmaProducts {
        productId
        quantity
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          sku
          ean
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
        }
      }
      shippingLocation {
        companyName
        secondaryAddress
        country
        state
        phone
      }
      rmaDocuments {
        rmaDocumentId
        rmaId
        name
        path
        createdAt
      }
      pickupLocation {
        companyName
        secondaryAddress
        country
        state
        phone
      }
      rmaState {
        rmaStateId
        state
      }
      returnReason {
        returnReasonId
        reason
      }
    }
  }
`

export const UPDATE_RMA_MUTATION = gql`
  mutation UpdateRma(
    $rmaId: ID!
    $userId: BigInt
    $shippingLocationId: BigInt
    $rmaStateId: BigInt
    $rmaProducts: [CreateRmaProduct]
    $pickupLocationId: BigInt
    $title: String
    $status: RmaStatus
    $returnReasonId: BigInt
    $pickupStart: DateTime
    $pickupEnd: DateTime
  ) {
    updateRma(
      rmaId: $rmaId
      input: {
        userId: $userId
        rmaProducts: $rmaProducts
        shippingLocationId: $shippingLocationId
        rmaStateId: $rmaStateId
        pickupLocationId: $pickupLocationId
        title: $title
        status: $status
        returnReasonId: $returnReasonId
        pickupStart: $pickupStart
        pickupEnd: $pickupEnd
      }
    ) {
      rmaId
      title
      pickupStart
      pickupEnd
      currierError {
        code
        title
        message
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
      }
      user {
        userId
        email
        firstName
        lastName
      }
      rmaProducts {
        productId
        quantity
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          sku
          ean
          tariffNumber
        }
      }
      shippingLocation {
        companyName
        secondaryAddress
        country
        state
        phone
      }
      status
      rmaDocuments {
        rmaDocumentId
        rmaId
        name
        path
        createdAt
      }
      pickupLocation {
        companyName
        secondaryAddress
        country
        state
        phone

        address
      }
      rmaState {
        rmaStateId
        state
      }
      returnReason {
        returnReasonId
        reason
      }
    }
  }
`

export const FILE_UPLOAD_RMA_MUTATION = gql`
  mutation UpdateRma(
    $rmaId: ID!
    $rmaDocuments: [Upload]
    $rmaDocumentsPublic: Int
  ) {
    updateRma(
      rmaId: $rmaId
      input: {
        rmaDocuments: $rmaDocuments
        rmaDocumentsPublic: $rmaDocumentsPublic
      }
    ) {
      rmaId
    }
  }
`

export const CREATE_API_TOKEN_MUTATION = gql`
  mutation createApiToken($userId: BigInt!, $data: Dictionary) {
    createApiToken(userId: $userId, type: LOGIN, input: { data: $data }) {
      apiTokenId
      userId
      type
      expires
    }
  }
`

export const FILE_DELETE_MUTATION = gql`
  mutation DeleteRmaDocument($rmaDocumentId: BigInt!) {
    deleteRmaDocument(rmaDocumentId: $rmaDocumentId) {
      rmaDocumentId
    }
  }
`

export const USER_DELETE_MUTATION = gql`
  mutation updateUser($userId: BigInt!, $deletedAt: DateTime) {
    updateUser(userId: $userId, input: { deletedAt: $deletedAt }) {
      userId
    }
  }
`

export const PRODUCT_DELETE_MUTATION = gql`
  mutation UpdateProduct(
    $productId: BigInt!
    $deletedAt: DateTime
    $status: ProductStatus
  ) {
    updateProduct(
      productId: $productId
      input: { deletedAt: $deletedAt, status: $status }
    ) {
      productId
    }
  }
`

export const UPDATE_SETTINGS_MUTATION = gql`
  mutation UpdateAppSettingsMutation($input: [UpdateAppSettings]!) {
    updateAppSettings(input: $input) {
      key
      value
    }
  }
`

export const DELETE_SHIPPING_MUTATION = gql`
  mutation UpdateShippingLocations(
    $shippingLocationId: BigInt!
    $deletedAt: DateTime
  ) {
    updateShippingLocations(
      shippingLocationId: $shippingLocationId
      input: { deletedAt: $deletedAt }
    ) {
      shippingLocationId
    }
  }
`

export const CREATE_CURRIER_SERVICE_MUTATION = gql`
  mutation CreateCurrierSetting(
    $title: String!
    $currierService: CurrierService!
    $data: JSON!
  ) {
    createCurrierSetting(
      title: $title
      currierService: $currierService
      data: $data
    ) {
      currierSettingId
      title
      currierService
      data
      deletedAt
    }
  }
`

export const UPDATE_CURRIER_SERVICE_MUTATION = gql`
  mutation UpdateCurrierSetting(
    $currierSettingId: BigInt!
    $title: String
    $currierService: CurrierService
    $data: JSON
    $deletedAt: DateTime
  ) {
    updateCurrierSetting(
      currierSettingId: $currierSettingId
      input: {
        title: $title
        currierService: $currierService
        data: $data
        deletedAt: $deletedAt
      }
    ) {
      currierSettingId
      title
      currierService
      data
      deletedAt
    }
  }
`

export const DELETE_CURRIER_SERVICE_MUTATION = gql`
  mutation DeleteCurrierService(
    $currierSettingId: BigInt!
    $deletedAt: DateTime
  ) {
    updateCurrierSetting(
      currierSettingId: $currierSettingId
      input: { deletedAt: $deletedAt }
    ) {
      currierSettingId
    }
  }
`

export const DELETE_PICKUP_MUTATION = gql`
  mutation UpdatePickupLocations(
    $pickupLocationId: BigInt!
    $deletedAt: DateTime
  ) {
    updatePickupLocations(
      pickupLocationId: $pickupLocationId
      input: { deletedAt: $deletedAt }
    ) {
      pickupLocationId
    }
  }
`

export const CREATE_RETURN_REASON = gql`
  mutation CreateRmaReason($reason: String!) {
    createRmaReason(reason: $reason) {
      returnReasonId
    }
  }
`

export const RETURN_REASON_DELETE_MUTATION = gql`
  mutation DeleteReturnReason($returnReasonId: BigInt!, $deletedAt: DateTime) {
    updateRmaReason(
      returnReasonId: $returnReasonId
      input: { deletedAt: $deletedAt }
    ) {
      returnReasonId
    }
  }
`

export const CREATE_RMA_STATE = gql`
  mutation CreateRmaState($state: String!) {
    createRmaState(state: $state) {
      rmaStateId
    }
  }
`

export const CREATE_RMA_MESSAGE = gql`
  mutation CreateRmaComment(
    $rmaId: ID!
    $comment: String!
    $visibility: CommentVisibility
    $notifiedUsers: [BigInt]
  ) {
    createRmaComment(
      rmaId: $rmaId
      comment: $comment
      input: { visibility: $visibility, notifiedUsers: $notifiedUsers }
    ) {
      rmaCommentId
    }
  }
`

export const UPDATE_RMA_MESSAGE = gql`
  mutation UpdateRmaComment(
    $rmaCommentId: BigInt!
    $comment: String
    $read: Int
    $deletedAt: DateTime
    $visibility: CommentVisibility
    $notifiedUsers: [BigInt]
  ) {
    updateRmaComment(
      rmaCommentId: $rmaCommentId
      input: {
        comment: $comment
        read: $read
        deletedAt: $deletedAt
        visibility: $visibility
        notifiedUsers: $notifiedUsers
      }
    ) {
      rmaCommentId
    }
  }
`

export const RMA_STATE_DELETE_MUTATION = gql`
  mutation DeleteRmaState($rmaStateId: BigInt!, $deletedAt: DateTime) {
    updateRmaState(rmaStateId: $rmaStateId, input: { deletedAt: $deletedAt }) {
      rmaStateId
    }
  }
`

export const CREATE_EMAIL_TEMPLATE = gql`
  mutation CreateEmailTemplate(
    $subject: String!
    $html: String!
    $template: JSON!
    $type: EmailType
    $language: Language
    $entityId: ID
    $text: String
  ) {
    createEmailTemplate(
      subject: $subject
      html: $html
      template: $template
      input: {
        type: $type
        language: $language
        entityId: $entityId
        text: $text
      }
    ) {
      emailTemplateId
    }
  }
`

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate(
    $emailTemplateId: BigInt!
    $subject: String
    $html: String
    $template: JSON
    $type: EmailType
    $language: Language
    $entityId: ID
    $text: String
  ) {
    updateEmailTemplate(
      emailTemplateId: $emailTemplateId
      input: {
        subject: $subject
        html: $html
        template: $template
        type: $type
        language: $language
        entityId: $entityId
        text: $text
      }
    ) {
      emailTemplateId
    }
  }
`

export const DELETE_EMAIL_TEMPLATE = gql`
  mutation DeleteEmailTemplate(
    $emailTemplateId: BigInt!
    $deletedAt: DateTime
  ) {
    updateEmailTemplate(
      emailTemplateId: $emailTemplateId
      input: { deletedAt: $deletedAt }
    ) {
      emailTemplateId
    }
  }
`

export const CANCEL_RMA_MUTATION = gql`
  mutation CancelRma($rmaId: ID!, $reason: String) {
    cancelRma(reason: $reason, rmaId: $rmaId) {
      rmaId
    }
  }
`

export const SYNC_SHOPIFY_PRODUCTS = gql`
  mutation ImportProducts {
    importProducts {
      status
      message
    }
  }
`

export const REMOVE_INTEGRATION = gql`
  mutation DisconnectStore($type: Shop!) {
    disconnectStore(type: $type) {
      status
      message
    }
  }
`

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $userId: BigInt!
    $name: String!
    $price: Float!
    $currency: CurrencyCode!
    $orderItems: [CreateOrderItems!]!
    $customerLocationId: BigInt!
    $companyLocationId: BigInt!
    $discount: Float
    $tax: Float
  ) {
    createOrder(
      userId: $userId
      name: $name
      price: $price
      currency: $currency
      orderItems: $orderItems
      customerLocationId: $customerLocationId
      companyLocationId: $companyLocationId
      input: { discount: $discount, tax: $tax }
    ) {
      orderDocuments {
        public
        orderDocumentId
        name
        path
        createdAt
        deletedAt
      }
      customerLocation {
        pickupLocationId
        userId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      companyLocation {
        shippingLocationId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      orderId
      user {
        userId
        email
        firstName
        lastName
      }
      shop
      name
      price
      discount
      currency
      tax
      orderItems {
        name
        quantity
        discount
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          sku
          serialNumber
          weight
          width
          height
          depth
          ean
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
      }
      externalOrderId
      externalOrderNumber
      externalCustomerId
      externalEmail
      externalCreatedAt
      externalClosedAt
    }
  }
`

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $orderId: BigInt!
    $customerLocationId: BigInt
    $companyLocationId: BigInt
    $name: String
    $price: Float
    $currency: CurrencyCode
    $orderItems: [CreateOrderItems]
    $discount: Float
    $tax: Float
    $orderDocuments: [Upload]
    $orderDocPublic: Int
  ) {
    updateOrder(
      orderId: $orderId
      input: {
        customerLocationId: $customerLocationId
        companyLocationId: $companyLocationId
        name: $name
        price: $price
        currency: $currency
        orderItems: $orderItems
        discount: $discount
        tax: $tax
        orderDocuments: $orderDocuments
        orderDocPublic: $orderDocPublic
      }
    ) {
      orderDocuments {
        public
        orderDocumentId
        name
        path
        createdAt
        deletedAt
      }
      customerLocation {
        pickupLocationId
        userId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      companyLocation {
        shippingLocationId
        companyName
        address
        secondaryAddress
        zip
        city
        country
        state
        phone
        deletedAt
      }
      orderId
      user {
        userId
        email
        firstName
        lastName
      }
      shop
      name
      price
      discount
      currency
      tax
      orderItems {
        name
        quantity
        discount
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          sku
          serialNumber
          weight
          width
          height
          depth
          ean
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
      }
      externalOrderId
      externalOrderNumber
      externalCustomerId
      externalEmail
      externalCreatedAt
      externalClosedAt
    }
  }
`

export const DELETE_ORDER = gql`
  mutation DeleteOrder($orderId: BigInt!) {
    deleteOrder(orderId: $orderId) {
      orderId
    }
  }
`

export const CREATE_SHIP_OUT = gql`
  mutation CreateShipOut(
    $orderId: BigInt!
    $title: String!
    $pickupAt: Date!
    $currierSettingId: BigInt!
    $shipOutProducts: [MutateShipOutProduct]!
    $state: ShipState
    $status: ShipStatus
    $shipOutDocuments: [Upload]
  ) {
    createShipOut(
      orderId: $orderId
      title: $title
      pickupAt: $pickupAt
      currierSettingId: $currierSettingId
      shipOutProducts: $shipOutProducts
      input: {
        state: $state
        status: $status
        shipOutDocuments: $shipOutDocuments
      }
    ) {
      shipOutId
      orderId
      user {
        userId
        email
        firstName
        lastName
        deletedAt
      }
      currierSetting {
        currierSettingId
        title
        currierService
        data
        createdAt
        deletedAt
      }
      shipOutProducts {
        shipOutProductId
        productId
        quantity
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
        serialNumber
      }
      title
      state
      status
      createdAt
      pickupAt
      shipOutDocuments {
        shipOutDocumentId
        name
        path
        createdAt
        deletedAt
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
        deletedAt
      }
      deletedAt
    }
  }
`

export const UPDATE_SHIP_OUT_STATE = gql`
  mutation UpdateShipOutState($shipOutId: ID!, $state: ShipState!) {
    updateShipOutState(shipOutId: $shipOutId, state: $state) {
      shipOutId
      orderId
      user {
        userId
        email
        firstName
        lastName
        deletedAt
      }
      currierSetting {
        currierSettingId
        title
        currierService
        data
        createdAt
        deletedAt
      }
      shipOutProducts {
        shipOutProductId
        productId
        quantity
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
        serialNumber
      }
      title
      state
      status
      createdAt
      pickupAt
      shipOutDocuments {
        shipOutDocumentId
        name
        path
        createdAt
        deletedAt
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
        deletedAt
      }
      deletedAt
    }
  }
`

export const UPDATE_SHIP_OUT_STATUS = gql`
  mutation UpdateShipOutStatus($shipOutId: ID!, $status: ShipStatus!) {
    updateShipOutStatus(shipOutId: $shipOutId, status: $status) {
      shipOutId
      orderId
      user {
        userId
        email
        firstName
        lastName
        deletedAt
      }
      currierSetting {
        currierSettingId
        title
        currierService
        data
        createdAt
        deletedAt
      }
      shipOutProducts {
        shipOutProductId
        productId
        quantity
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
        serialNumber
      }
      title
      state
      status
      createdAt
      pickupAt
      shipOutDocuments {
        shipOutDocumentId
        name
        path
        createdAt
        deletedAt
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
        deletedAt
      }
      deletedAt
    }
  }
`

export const UPDATE_SHIP_OUT = gql`
  mutation UpdateShipOut(
    $shipOutId: ID!
    $pickupAt: Date!
    $shipOutProducts: [UpdateShipOutProduct]
  ) {
    updateShipOut(
      shipOutId: $shipOutId
      input: { pickupAt: $pickupAt, shipOutProducts: $shipOutProducts }
    ) {
      shipOutId
      orderId
      user {
        userId
        email
        firstName
        lastName
        deletedAt
      }
      currierSetting {
        currierSettingId
        title
        currierService
        data
        createdAt
        deletedAt
      }
      shipOutProducts {
        shipOutProductId
        productId
        quantity
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
        serialNumber
      }
      title
      state
      status
      createdAt
      pickupAt
      shipOutDocuments {
        shipOutDocumentId
        name
        path
        createdAt
        deletedAt
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
        deletedAt
      }
      deletedAt
    }
  }
`

export const DELETE_SHIP_OUT = gql`
  mutation DeleteShipOut($shipOutId: ID!) {
    deleteShipOut(shipOutId: $shipOutId) {
      shipOutId
      orderId
      user {
        userId
        email
        firstName
        lastName
        deletedAt
      }
      currierSetting {
        currierSettingId
        title
        currierService
        data
        createdAt
        deletedAt
      }
      shipOutProducts {
        shipOutProductId
        productId
        quantity
        price
        currency
        product {
          productId
          name
          price
          currency
          status
          serialNumber
          weight
          width
          height
          depth
          identificationNumber
          tariffNumber
          productLabels {
            productLabelId
            productId
            productLabel {
              label
              serviceCode
              contentId
              unCode
              deletedAt
            }
          }
          deletedAt
        }
        serialNumber
      }
      title
      state
      status
      createdAt
      pickupAt
      shipOutDocuments {
        shipOutDocumentId
        name
        path
        createdAt
        deletedAt
      }
      currier {
        currierId
        shipmentTrackingNumber
        trackingNumber
        dispatchConfirmationNumber
        currency
        price
        deletedAt
      }
      deletedAt
    }
  }
`

export const DELETE_SHIP_OUT_DOCUMENT = gql`
  mutation DeleteShipOutDocument($shipOutDocumentId: ID!) {
    deleteShipOutDocument(shipOutDocumentId: $shipOutDocumentId) {
      shipOutDocumentId
      name
      path
      createdAt
      deletedAt
    }
  }
`

export const DELETE_ORDER_DOCUMENT = gql`
  mutation DeleteOrderDocument($orderDocumentId: ID!) {
    deleteOrderDocument(orderDocumentId: $orderDocumentId) {
      orderDocumentId
      name
      path
      createdAt
      deletedAt
    }
  }
`

export const SCHEDULE_SHIPMENT_PICKUP = gql`
  mutation SchedulePickup($pickupAt: DateTime) {
    schedulePickup(pickupAt: $pickupAt) {
      status
      message
    }
  }
`

export const CREATE_API_TOKEN = gql`
  mutation CreateApiToken(
    $userId: BigInt!
    $type: ApiType!
    $expires: DateTime
  ) {
    createApiToken(userId: $userId, type: $type, input: { expires: $expires }) {
      apiTokenId
      userId
      type
      expires
    }
  }
`

export const DELETE_API_TOKEN = gql`
  mutation DeleteApiToken($apiTokenId: ID!) {
    deleteApiToken(apiTokenId: $apiTokenId) {
      apiTokenId
      userId
      type
      expires
    }
  }
`
