import { useState } from 'react'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { useAuth } from '../../contexts/AuthenticationContext'
import {
  Drawer as MuiDrawer,
  BoxProps,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Tooltip,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { DrawerMenu } from '../DrawerMenu'
import { Box, styled } from '@mui/system'
import { usePageTitle } from '../../contexts/TitleContext'
import { useHistory } from 'react-router'

const DRAWER_WIDTH = 240

const ApplicationWrapper = styled(Box)<BoxProps>(() => ({
  height: '100%',
}))

const ContentWrapper = styled(Box)<BoxProps>(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

interface IProps {
  children: JSX.Element
}

export const HeaderAndDrawer = ({ children }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileOpen, setMobileOpen] = useState(false)
  const { user, logout } = useAuth()
  const history = useHistory()
  const { title } = usePageTitle()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (path?: string) => {
    setAnchorEl(null)
    path && history.push(path)
  }

  const logoutClicked = () => {
    handleClose()
    logout()
  }

  return (
    <ApplicationWrapper>
      <AppBar
        position="fixed"
        // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        sx={{
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { md: `${DRAWER_WIDTH}px` },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={handleDrawerToggle}
            aria-label="menu"
            sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Tooltip title={`${title}`} enterTouchDelay={0}>
            <Typography
              noWrap
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
          </Tooltip>
          {user && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item>
                <Tooltip
                  title={`${user.email} - ${user.role}`}
                  enterTouchDelay={0}
                >
                  <Typography noWrap>
                    {user.email} - {user.role}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Grid>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
              >
                <MenuItem onClick={() => handleClose('/account')}>
                  My account
                </MenuItem>
                <MenuItem onClick={logoutClicked}>Logout</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <MuiDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
            },
          }}
        >
          <DrawerMenu />
        </MuiDrawer>
        <MuiDrawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
            },
          }}
          open
        >
          <DrawerMenu />
        </MuiDrawer>
      </Box>
      <ContentWrapper
        component="main"
        sx={{
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { md: `${DRAWER_WIDTH}px` },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            position: 'relative',
          }}
          p={3}
        >
          {children}
        </Box>
      </ContentWrapper>
    </ApplicationWrapper>
  )
}
