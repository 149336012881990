/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

interface IProps {
  url: string
  loaded?: React.Dispatch<React.SetStateAction<boolean>>
}

export const useScript = ({ url }: IProps): boolean => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true

    document.body.appendChild(script)

    script.onload = () => {
      setLoaded(true)
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return loaded
}
