import { createContext, useContext } from 'react'
import { useScript } from '../hooks/useExternalScript'

type IGoogleApi = boolean

const GoogleApi = createContext<IGoogleApi>(false)

export const useGoogleApi = () => {
  return useContext(GoogleApi)
}

interface IProps {
  children: React.ReactNode
}

export const GoogleApiProvider = ({ children }: IProps) => {
  const isApiLoaded = useScript({
    url: `https://maps.googleapis.com/maps/api/js?key=AIzaSyD-EQ5oFkG4iLUQC40MX5y79YjRjH5IS44&libraries=places`,
  })

  return <GoogleApi.Provider value={isApiLoaded}>{children}</GoogleApi.Provider>
}
