import {
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  useMutation as useGQLMutation,
} from '@apollo/client'
import { Alert } from '@mui/material'
import { DocumentNode } from 'graphql'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'

export function useMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
  const result = useGQLMutation(mutation, options)
  const { enqueueSnackbar } = useSnackbar()
  const { loading, error } = result[1]

  useEffect(() => {
    error &&
      !loading &&
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 2000,
      })
  }, [error, loading, enqueueSnackbar])

  return result
}
