import { useEffect } from 'react'
import { useMetadata } from '../contexts/MetadataProvider'
import { getHost } from '../utils'

export const FaviconFetcher = () => {
  const { metadata, loading } = useMetadata()
  const getFaviconEl = (): HTMLAnchorElement | null =>
    document.getElementById('favicon') as HTMLAnchorElement

  useEffect(() => {
    if (loading || !metadata.favicon) return
    const faviconURL = `${getHost()}/api/static/favicon/${metadata.favicon}`

    fetch(faviconURL)
      .then((res) => {
        const favicon = getFaviconEl()

        if (res.ok && favicon) {
          favicon.setAttribute('href', faviconURL)
        }
      })
      .catch((e: any) => console.log(e.message))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return null
}
