import {
  AppBar,
  Button,
  Container,
  ContainerProps,
  Grid,
  Toolbar,
} from '@mui/material'
import { useAuth } from '../../contexts/AuthenticationContext'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { Box, BoxProps, styled } from '@mui/system'
import { Logo } from '../Logo'
import { Image } from '../Image'
import { getHost } from '../../utils'
import { useMetadata } from '../../contexts/MetadataProvider'

const ApplicationWrapper = styled(Box)<BoxProps>(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

const CustomContainer = styled(Container)<ContainerProps>(({ theme }) => {
  return {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '900px !important',
  }
})
interface IProps {
  children: JSX.Element
}

export const HeaderOnly = ({ children }: IProps) => {
  const { user, logout } = useAuth()
  const { metadata } = useMetadata()

  const logoutClicked = () => {
    logout()
  }

  return (
    <ApplicationWrapper>
      <AppBar position="fixed">
        <Toolbar>
          <CustomContainer>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: 64 }}
            >
              <Image
                boxProps={{
                  sx: {
                    width: 'auto',
                    height: 40,
                    filter: 'brightness(0) invert(1)',
                    img: {
                      maxWidth: '100%',
                      maxHeight: '100%',
                    },
                  },
                }}
                src={`${getHost()}/api/static/logo/${metadata.logo}`}
                backup={<Logo maxHeight={40} type="logo-normal-inverse" />}
              />
              {user && (
                <Button
                  onClick={logoutClicked}
                  aria-label="menu"
                  variant="contained"
                  sx={{ ml: 'auto' }}
                  startIcon={<AccountCircle />}
                >
                  Logout
                </Button>
              )}
            </Grid>
          </CustomContainer>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box sx={{ position: 'relative', height: '100%' }}>{children}</Box>
    </ApplicationWrapper>
  )
}
