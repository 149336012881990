import { useAuth } from '../../contexts/AuthenticationContext'
import { HeaderAndDrawer } from './HeaderAndDrawer'
import { HeaderOnly } from './HeaderOnly'

interface IProps {
  children: JSX.Element
}

export const AppHeader = ({ children }: IProps) => {
  const { user } = useAuth()

  if (!user || user.role === 'USER') {
    return <HeaderOnly>{children}</HeaderOnly>
  }

  return <HeaderAndDrawer>{children}</HeaderAndDrawer>
}
