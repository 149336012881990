/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router'
import { PrivateRoute } from './components/PrivateRoute'
import { useAuth } from './contexts/AuthenticationContext'
import './App.scss'
import { AbsoluteLoading, BigLoading } from './components/Loading'
import { AppHeader } from './components/headers'
import { Box } from '@mui/system'
import { useURLQuery } from './hooks/useURLQuery'
import { GET_LOGGED_IN_USER } from './apollo/queries'
import { useImperativeLazyQuery } from './hooks/useLazyQuery'
import { User } from './types/user'
import { useMetadata } from './contexts/MetadataProvider'
import { useSettings } from './contexts/SettingsContext'

// const PublicPage = lazy(() => import('./layout/PublicPage'))
const LoginPage = lazy(() => import('./layout/public/LoginPage'))
const NotFoundPage = lazy(() => import('./layout/public/NotFoundPage'))
const ReturnRouterPage = lazy(
  () => import('./layout/authenticated/return/ReturnRouterPage'),
)
const ShipRouterPage = lazy(
  () => import('./layout/authenticated/ship/ShipRouterPage'),
)
const UserRouterPage = lazy(
  () => import('./layout/authenticated/user/UserRouterPage'),
)
const CompanyLocationsPage = lazy(
  () => import('./layout/authenticated/companyLocations/CompanyLocationsPage'),
)
const UsersRouter = lazy(
  () => import('./layout/authenticated/users/UsersRouter'),
)
const ProductRouter = lazy(
  () => import('./layout/authenticated/products/ProductRouter'),
)
const CustomerLocationsPage = lazy(
  () =>
    import('./layout/authenticated/customerLocations/CustomerLocationsPage'),
)
const ProfilePage = lazy(
  () => import('./layout/authenticated/account/ProfilePage'),
)
const SettingsRouter = lazy(
  () => import('./layout/authenticated/settings/SettingsRouter'),
)
const EmailRouter = lazy(
  () => import('./layout/authenticated/emails/EmailRouter'),
)
// const AppHomePage = lazy(() => import('./layout/authenticated'))

export const USER_HOMEPAGE_PATH = '/me'
export const AGENT_HOMEPAGE_PATH = '/return'

const App = () => {
  const { user, loading } = useAuth()
  const checkUser = useImperativeLazyQuery(GET_LOGGED_IN_USER)
  const history = useHistory()
  const location = useLocation()
  const query = useURLQuery()

  // Some redirection magic, read carefully
  useEffect(() => {
    if (!user && !loading) return

    const rmaId = query.get('rmaId')
    const orderId = query.get('orderId')
    let userHomepage = USER_HOMEPAGE_PATH
    let agentHomepage = AGENT_HOMEPAGE_PATH

    // When we login with the token, we provide rmaId parameter
    if (rmaId) {
      userHomepage += `/rma/${rmaId}`
    }

    if (orderId) {
      userHomepage += `/order/${orderId}`
    }

    /*
      This is needed otherwise redirect to RMA ID fails.

      The whole logic is pretty spaghetti code, I apologize.
      I was adding one thing on top of another.
    */
    checkUser({ fetchPolicy: 'network-only' })
      .then((data: any) => {
        const actualUser: User = data.data.user

        if (actualUser && location.pathname === '/login') {
          if (actualUser.role === 'USER') {
            history.replace({ pathname: userHomepage })
          } else {
            history.replace({ pathname: agentHomepage })
          }
          return
        }

        if (actualUser) {
          if (
            actualUser.role === 'USER' &&
            location.pathname === agentHomepage
          ) {
            history.replace({ pathname: userHomepage })
          }
          if (
            actualUser.role !== 'USER' &&
            (location.pathname.startsWith(userHomepage) ||
              location.pathname === '/')
          ) {
            history.replace({ pathname: agentHomepage })
          }
          return
        }
      })
      .catch((e: any) => {
        console.log(e.message)
      })
  }, [user, loading])

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        minHeight: '100vh',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <AppHeader>
        <Suspense fallback={<AbsoluteLoading />}>
          <Switch location={location}>
            {/* <Route path="/public">
              <PublicPage />
            </Route> */}
            <PrivateRoute
              roles={['AGENT', 'ADMIN', 'USER']}
              path="/"
              exact
              component={
                <Redirect
                  to={
                    user?.role === 'USER'
                      ? USER_HOMEPAGE_PATH
                      : AGENT_HOMEPAGE_PATH
                  }
                />
              }
            />
            <Route path="/login" component={LoginPage} />
            <PrivateRoute
              roles={['USER']}
              path="/me"
              component={<UserRouterPage />}
            />
            <PrivateRoute
              roles={['AGENT', 'ADMIN']}
              path="/account"
              component={<ProfilePage />}
            />
            <PrivateRoute
              roles={['AGENT', 'ADMIN']}
              path="/return"
              component={<ReturnRouterPage />}
            />
            <PrivateRoute
              roles={['AGENT', 'ADMIN']}
              path="/ship"
              component={<ShipRouterPage />}
            />
            <PrivateRoute
              roles={['AGENT', 'ADMIN']}
              path="/products"
              component={<ProductRouter />}
            />
            <PrivateRoute
              roles={['AGENT', 'ADMIN']}
              path="/users"
              component={<UsersRouter />}
            />
            <PrivateRoute
              roles={['ADMIN']}
              path="/settings"
              component={<SettingsRouter />}
            />
            <PrivateRoute
              roles={['AGENT', 'ADMIN']}
              path="/company-location"
              component={<CompanyLocationsPage />}
            />
            <PrivateRoute
              roles={['AGENT', 'ADMIN']}
              path="/customer-location"
              component={<CustomerLocationsPage />}
            />
            <PrivateRoute
              roles={['AGENT', 'ADMIN']}
              path="/emails"
              component={<EmailRouter />}
            />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Suspense>
      </AppHeader>
    </Box>
  )
}

export default App
